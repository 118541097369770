module.exports = {
  headerHeight: 500,
  content: ["./src/**/*.{js,jsx,ts,tsx}"],
  theme: {
    fontFamily: {
      body: ["itc-avant-garde-gothic-pro", "sans-serif"],
    },
    extend: {
      colors: {
        blue: {
          300: "#8ED0F4",
          400: "#17A5E2",
          500: "#4077C6",
          550: "#449CD5",
          700: "#0F2D5D",
        },
        gray: {
          200: "#A6A6A7",
          250: "#EAEAEA",
          300: "#E5E5E5",
        },
        yellow: {
          400: "#F5C648",
        },
        teal: {
          600: "#007A7F",
          650: "#0D7A7F",
        },
      },
      spacing: {
        "1/1": "100%",
        "2/3": "66.666667%",
        "16/8": "50.00%",
        "16/9": "56.25%",
      },
      minHeight: {
        0: "0",
        "1/4": "25%",
        "1/2": "50%",
        "3/4": "75%",
        full: "100%",
      },
      maxWidth: {
        "content-area": "1400px",
      },
    },
  },
  plugins: [],
};
