import { useState, useLayoutEffect } from "react";
import { useWindowSize } from "./useWindowSize";

export const useContentSectionHeight = (elementRef, isLoading) => {
  const { height } = useWindowSize();
  const [contentSectionHeight, setContentSectionHeight] = useState(400);

  useLayoutEffect(() => {
    try {
      if (!isLoading) {
        const newHeight = height - (elementRef.current.offsetTop + 75);

        setContentSectionHeight(newHeight);
      }
    } catch (ex) {}
  }, [isLoading, height]);

  return {
    height: contentSectionHeight,
  };
};
