import Layout from "../../components/Layout";
import Stylewind from "../../components/Stylewind";
import config from "../../tailwind.config";
import skatesImg from "./roller_skates.png";
import arImg from "./ar.png";
import owlImg from "./owl.png";
import googlePlayImg from "./google_play_btn.png";
import appStoreImg from "./app_store_btn.png";
import stickersImg from "./stickers.png";
import expressImg from "./express_background.png";
import augmentImg from "./augment_circle.png";
import salesforceLogo from "./salesforce_logo.png";
import eventmojisLogo from "./eventmojisLogo.svg";
import Navigation from "../../components/Navigation";
import makeTrailmoji from "./make_trailmoji.png";

const HeroContent = Stylewind``;
HeroContent.defaultProps = {
  className:
    "text-white flex md:flex-row flex-col md:items-center md:justify-center text-xl md:text-5xl font-bold max-w-20 mx-auto",
};

const HeroColumn = Stylewind``;
HeroColumn.defaultProps = {
  className:
    "w-2/3 md:w-1/2 flex flex-col md:items-center md:justify-center md:p-16 p-4",
};

const ContentContainer = Stylewind`
  margin-top: calc(${config.headerHeight}px;
`;
ContentContainer.defaultProps = {
  className:
    "w-full flex flex-col items-center justify-center md:px-16 bg-gray-300",
};

const Content = Stylewind``;

Content.defaultProps = {
  className:
    "flex flex-col md:items-center md:justify-center bg-white shadow-x-lg box-border grow-0 max-w-content-area",
};

const FooterContainer = Stylewind`
  margin-top: calc(${config.headerHeight}px;
`;
FooterContainer.defaultProps = {
  className:
    "w-full flex flex-col items-center justify-center md:px-16 md:pb-16 bg-blue-300",
};

const FooterContent = Stylewind``;

FooterContent.defaultProps = {
  className:
    "flex flex-col items-center justify-center bg-white shadow-x-lg shadow-b-lg  box-border grow-0 md:max-w-content-area md:w-content pb-16",
};

const FooterTextBlock = () => {
  return (
    <p className="text-gray-400">
      © Copyright 2022 Salesforce.com, inc. All rights reserved. Various
      trademarks held by their respective owners. Salesforce.com, inc.
      Salesforce Tower, 415 Mission Street, 3rd Floor, San Francisco, CA 94105,
      United States
    </p>
  );
};

const Home = () => (
  <Layout
    backgroundType="main"
    hero={
      <HeroContent>
        <HeroColumn addClassName="md:ml-12 ml-0">
          Create anything that inspires you. 😁
        </HeroColumn>
        <HeroColumn></HeroColumn>
      </HeroContent>
    }
    addClassName="flex-col-reverse"
  >
    <ContentContainer>
      <Content>
        <div className="flex lg:flex-row flex-col w-full mt-20 px-10">
          <div className="w-full lg:w-2/3 flex justify-start items-start lg:flex-row flex-col-reverse relative">
            <div className="relative text-left flex items-start justify-start">
              <img src={skatesImg} alt="" />
              <img
                src={owlImg}
                alt=""
                width="180"
                className="absolute bottom-0 -right-20 lg:-bottom-8 lg:-right-32"
              />
            </div>
            <div className="flex flex-col space-y-4 px-8">
              <h3 className="text-blue-500 text-2xl font-bold">
                Express yourself right here...
              </h3>
              <p className="text-gray-200">
                Use Stickers to say it with flare, or make your own Astro with
                myTrailmoji
              </p>
            </div>
          </div>
          <div className="lg:w-1/3 flex lg:flex-row flex-col-reverse">
            <img
              src={arImg}
              alt=""
              width="180"
              className="rounded-lg shadow-lg mx-auto lg:mx-0"
            />
            <div className="flex flex-col space-y-4 px-8">
              <h3 className="text-blue-500 text-2xl font-bold">
                ...or download for more
              </h3>
              <p className="text-gray-200">
                Use the app for AR filters to bring Astro along wherever you go.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-yellow-400 w-full text-center py-10 my-16">
          <div
            className="text-center flex justify-center items-center space-x-2"
            id="apps"
          >
            <a href="https://apps.apple.com/us/app/trailmoji/id1294827604">
              <img src={appStoreImg} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.salesforce.dreamoji">
              <img src={googlePlayImg} />
            </a>
          </div>
        </div>
        <div className="flex justify-center items-center py-16">
          <div className="w-3/5">
            <img src={stickersImg} alt="" width="500" />
          </div>
          <div className="w-2/5 flex flex-col space-y-10">
            <h3 className="text-blue-500 text-2xl font-bold">
              Stickers. 😜 When words just aren’t enough.
            </h3>
            <p className="text-gray-200 self-center p-0 m-0">
              Say it with a sticker. A trailmoji sticker to be exact.
            </p>
            <span className="w-full pt-4">
              <a
                href="/stickers"
                className="bg-blue-550 text-white px-10 py-4 rounded-lg"
              >
                Let's go!
              </a>
            </span>
          </div>
        </div>
        <div
          id="express"
          className="bg-teal-650 w-full relative pb-20 bg-contain bg-no-repeat bg-right-bottom"
          style={{ backgroundImage: `url(${expressImg})` }}
        >
          <div className="flex flex-col ml-20 mt-20 w-1/2 space-y-8">
            <h3 className="text-white text-2xl font-bold">
              Express yourself. 👉
            </h3>
            <p className="text-white">
              Get creative. Join the Trailhead community and create your own
              myTraiomoji. Let the world see your inner-moji.
            </p>
            <span className="w-full pt-4">
              <a
                href="/mytrailmoji"
                className="bg-blue-550 text-white px-10 py-4 rounded-lg"
              >
                Let's create!
              </a>
            </span>
          </div>
        </div>
        <div className="flex justify-center items-center py-16">
          <div className="w-3/5 text-center flex items-center justify-center">
            <img src={augmentImg} alt="" width="500" />
          </div>
          <div className="w-2/5 flex flex-col space-y-10">
            <h3 className="text-blue-500 text-2xl font-bold">
              Augment your reality. 🙈
            </h3>
            <p className="text-gray-200 self-center p-0 m-0">
              Astro... Codey... Cloudy in all the augmented reality bliss one
              could possibly imagine! Record and share the fun!
            </p>
            <span className="w-full pt-4">
              <a
                href="#apps"
                className="bg-blue-550 text-white px-10 py-4 rounded-lg"
              >
                Let's go!
              </a>
            </span>
          </div>
        </div>
      </Content>
    </ContentContainer>
    <FooterContainer>
      <FooterContent>
        <div className="flex justify-center items-center -mt-4 pb-8 space-x-8 px-4 md:px-0">
          <a href="https://www.salesforce.com/">
            <img src={salesforceLogo} alt="" width="170" />
          </a>
          <div className="flex flex-col ">
            <span>Powered by</span>
            <a href="https://eventmojis.com/">
              <img src={eventmojisLogo} alt="" width="240" />
            </a>
          </div>
        </div>
        <Navigation addClassName="text-gray-400" isFooter />
        <div className="w-2/3 m-auto mt-20">
          <FooterTextBlock />
        </div>
      </FooterContent>
    </FooterContainer>
  </Layout>
);

export default Home;
