import torso from "./avatar/torso.svg";
import eyes from "./avatar/eyes.svg";
import face from "./avatar/face";
import head from "./avatar/head.svg";
import hair from "./avatar/hair.svg";
import leftArm from "./avatar/leftArm.svg";
import rightArm from "./avatar/rightArm.svg";
import leftLeg from "./avatar/leftLeg.svg";
import rightLeg from "./avatar/rightLeg.svg";
import tail from "./avatar/tail.svg";
import tone1 from "./tone1.svg";
import tone2 from "./tone2.svg";
import tone3 from "./tone3.svg";
import tone4 from "./tone4.svg";
import tone5 from "./tone5.svg";
import tone6 from "./tone6.svg";
import tone7 from "./tone7.svg";
import tone8 from "./tone8.svg";
import tone9 from "./tone9.svg";
import tone10 from "./tone10.svg";
import tone11 from "./tone11.svg";
import tone12 from "./tone12.svg";
import tone13 from "./tone13.svg";
import tone14 from "./tone14.svg";
import tone15 from "./tone15.svg";
import tone16 from "./tone16.svg";
import tone17 from "./tone17.svg";

export const baseLayers = [
  {
    id: "eyes",
    svg: eyes,
    order: 40,
  },
  {
    id: "face",
    svg: face,
    order: 30,
  },
  {
    id: "head",
    svg: head,
    order: 32,
  },
  {
    id: "hair",
    svg: hair,
    order: 31,
  },
  {
    id: "torso",
    svg: torso,
    order: 20,
  },
  {
    id: "leftArm",
    svg: leftArm,
    order: 15,
  },
  {
    id: "rightArm",
    svg: rightArm,
    order: 15,
  },
  {
    id: "leftLeg",
    svg: leftLeg,
    order: 10,
  },
  {
    id: "rightLeg",
    svg: rightLeg,
    order: 10,
  },
  {
    id: "tail",
    svg: tail,
    order: 5,
  },
];

export const skinCategory = {
  id: "skinTone",
  title: "Skins",
  categoryThumbnail:
    "https://cdn.dreamoji.app/dreamojime/skin-category-icon.png",
  items: [
    { id: "skin1", thumbnailUrl: tone1, skinTone: "#F8DCBC", svgLayers: [] },
    { id: "skin2", thumbnailUrl: tone2, skinTone: "#FFCFA6", svgLayers: [] },
    { id: "skin3", thumbnailUrl: tone3, skinTone: "#EAC9A9", svgLayers: [] },
    { id: "skin4", thumbnailUrl: tone4, skinTone: "#FFC98F", svgLayers: [] },
    { id: "skin5", thumbnailUrl: tone5, skinTone: "#E29E6F", svgLayers: [] },
    { id: "skin6", thumbnailUrl: tone6, skinTone: "#DB9565", svgLayers: [] },
    { id: "skin7", thumbnailUrl: tone7, skinTone: "#CC925E", svgLayers: [] },
    { id: "skin8", thumbnailUrl: tone8, skinTone: "#D18C66", svgLayers: [] },
    { id: "skin9", thumbnailUrl: tone9, skinTone: "#BF9068", svgLayers: [] },
    { id: "skin10", thumbnailUrl: tone10, skinTone: "#BF8047", svgLayers: [] },
    { id: "skin11", thumbnailUrl: tone11, skinTone: "#9C653D", svgLayers: [] },
    { id: "skin12", thumbnailUrl: tone12, skinTone: "#8E4926", svgLayers: [] },
    { id: "skin13", thumbnailUrl: tone13, skinTone: "#9C653D", svgLayers: [] },
    { id: "skin14", thumbnailUrl: tone14, skinTone: "#8C5C28", svgLayers: [] },
    { id: "skin15", thumbnailUrl: tone15, skinTone: "#8E693C", svgLayers: [] },
    { id: "skin16", thumbnailUrl: tone16, skinTone: "#846349", svgLayers: [] },
    { id: "skin17", thumbnailUrl: tone17, skinTone: "#5A4539", svgLayers: [] },
  ],
};

export const colorSwatches = [
  "#111111",
  "#FEFEFE",
  "#5B3B2E",
  "#D83A00",
  "#FF784F",
  "#FEB9A5",
  "#4a2413",
  "#0B827C",
  "#04E1CB",
  "#acf3e4",
  "#024d4c",
  "#dd7a01",
  "#FE9339",
  "#FFBA90",
  "#825101",
  "#321D71",
  "#8a8ed1",
  "#c2c8e8",
  "#200d38",
  "#e4a201",
  "#FCC003",
  "#f9e3b6",
  "#A86403",
  "#481a54",
  "#730394",
  "#2E0039",
  "#396547",
  "#70bf75",
  "#cdefc4",
  "#1c3326",
  "#e26e8c",
  "#eebdc6",
  "#531a2b",
  "#194e31",
  "#91db8b",
  "#ebf7e6",
  "#0e3522",
];
