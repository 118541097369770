import { VirtuosoGrid } from "react-virtuoso";

import { Dialog } from "@headlessui/react";
import { useState, useEffect, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
import gql from "graphql-tag";
import "minifaker/locales/en";

import { useLocalStorage } from "../../hooks/useLocalStorage";
import Layout from "../../components/Layout";
import resolveConfig from "tailwindcss/resolveConfig";
import localConfig from "../../tailwind.config";
import { useQueryState } from "../../hooks/useQueryState";
import Loading from "react-loading";
import ErrorBlock from "../../components/ErrorBlock";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useContentSectionHeight } from "../../hooks/useContentSectionHeight";
import { giveLike } from "../../utils/stickersApi";

const config = resolveConfig(localConfig as any);

const queryListMyTrailmoji = gql`
  query QueryMyTrailmoji($nextToken: String) {
    listMyTrailmojis(nextToken: $nextToken) {
      items {
        id
        image
        name
        likeCount
        thumbnail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

const HeartActive = () => (
  <svg
    width="38"
    height="33"
    viewBox="0 0 38 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.626 1.33143C22.068 2.22067 20.3276 4.04593 19.0545 6.49132C18.9026 6.24561 18.8208 6.12861 18.7508 5.9999C16.9753 2.82909 14.2772 0.992122 10.5512 0.699611C6.84848 0.407101 3.90503 1.70585 2.04785 4.92346C-0.451746 9.27602 0.272436 13.4531 3.029 17.4312C4.48905 19.5373 6.46303 21.1637 8.42533 22.8134C10.9716 24.9429 13.5413 27.0373 16.0059 29.2603C17.1389 30.29 18.0383 31.5653 19.0661 32.7471C19.7553 31.8929 20.3743 30.9335 21.192 30.1847C23.493 28.0669 25.8758 26.0427 28.2235 23.9718C30.0223 22.3922 31.9262 20.9062 33.5848 19.2097C36.3998 16.3314 38.0818 12.8915 37.4277 8.8314C36.283 1.78775 30.5129 -0.71614 24.626 1.33143Z"
      fill="#F05D89"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.6261 1.33041C22.0681 2.21964 20.3278 4.04491 19.0546 6.49029C19.0546 6.47859 19.0429 6.47859 19.0429 6.46689L19.0312 32.6992C19.0429 32.7109 19.0546 32.7226 19.0546 32.7343C19.7438 31.8802 20.3628 30.9208 21.1804 30.172C23.4815 28.0542 25.8643 26.03 28.212 23.959C30.0108 22.3795 31.9147 20.8935 33.5733 19.197C36.3883 16.3187 38.0703 12.8787 37.4162 8.81868C36.2832 1.78672 30.5131 -0.717167 24.6261 1.33041Z"
      fill="#CA3D70"
    />
  </svg>
);

const HeartInactive = () => (
  <svg
    width="38"
    height="33"
    viewBox="0 0 38 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.626 1.33143C22.068 2.22067 20.3276 4.04593 19.0545 6.49132C18.9026 6.24561 18.8208 6.12861 18.7508 5.9999C16.9753 2.82909 14.2772 0.992122 10.5512 0.699611C6.84848 0.407101 3.90503 1.70585 2.04785 4.92346C-0.451746 9.27602 0.272436 13.4531 3.029 17.4312C4.48905 19.5373 6.46303 21.1637 8.42533 22.8134C10.9716 24.9429 13.5413 27.0373 16.0059 29.2603C17.1389 30.29 18.0383 31.5653 19.0661 32.7471C19.7553 31.8929 20.3743 30.9335 21.192 30.1847C23.493 28.0669 25.8758 26.0427 28.2235 23.9718C30.0223 22.3922 31.9262 20.9062 33.5848 19.2097C36.3998 16.3314 38.0818 12.8915 37.4277 8.8314C36.283 1.78775 30.5129 -0.71614 24.626 1.33143Z"
      fill="#EDEAEB"
    />
  </svg>
);

const Gallery = () => {
  const gray = (config as any).theme.colors.gray["300"];

  const [state, setState] = useState<{
    avatars: any[];
    isDoneLoading: boolean;
    token: any;
  }>({
    avatars: [],
    token: undefined,
    isDoneLoading: false,
  });

  const [isErrored, setIsErrored] = useState<boolean>(false);
  const [selectedAvatarId, setSelectedAvatarId] = useQueryState(
    "avatarId",
    null
  );

  const [filter, setFilter] = useState<string>();
  const [localLikes, setLocalLikes] = useState({});
  const { height } = useWindowSize();
  const [favorites, setFavorites] = useLocalStorage("favorites", {});
  const isLoading = state.avatars.length === 0;
  let selectedAvatar: any = state.avatars.find(
    (avatar) => (avatar as any)?.id === selectedAvatarId
  );

  const itemClassName = height <= 900 ? "w-[200px] p-4" : "w-[315px] p-4";
  const onClose = () => {
    setSelectedAvatarId(undefined);
  };

  const fetchAvatars = async (nextToken = state.token) => {
    try {
      const result = await API.graphql(
        graphqlOperation(queryListMyTrailmoji, { nextToken })
      );

      const resultData = (result as any).data.listMyTrailmojis;
      const newState = {
        avatars: [...state.avatars, ...resultData.items],
        isDoneLoading: resultData.nextToken === null,
        token: resultData.nextToken,
      };

      setState(newState);
      setIsErrored(false);
    } catch (error) {
      setIsErrored(true);
      console.error(`Error: ${JSON.stringify(error)}`);
    }
  };

  useEffect(() => {
    if (
      // If there's a nextToken
      (!state.isDoneLoading && state.token) ||
      // or it's our first time through
      (state.avatars.length === 0 && !state.isDoneLoading)
    ) {
      fetchAvatars();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isDoneLoading, state.token]);

  useEffect(() => {
    // Sort the collection after all records are finished loading
    if (state.isDoneLoading && state.avatars && state.avatars.length > 0) {
      const sorted: any = (state.avatars as any).sort(function (
        a: any,
        b: any
      ) {
        if (a.createdAt > b.createdAt) {
          return -1;
        } else if (a.createdAt < b.createdAt) {
          return 1;
        } else {
          return 0;
        }
      });

      setState((p) => ({ ...p, avatars: sorted }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isDoneLoading]);

  const filteredAvatars = filter
    ? state.avatars.filter((avatar: any) => {
        return avatar?.name?.toLowerCase().includes(filter.toLowerCase());
      })
    : state.avatars;

  const blockRef: any = useRef();
  const { height: sectionHeight } = useContentSectionHeight(
    blockRef,
    !state.isDoneLoading
  );

  return (
    <Layout backgroundType="gallery" rootBackgroundColor={gray}>
      <div className="flex flex-col mt-16 w-5/6 mx-auto">
        <input
          type="text"
          className="w-4/6 rounded-xl mb-9 py-4 px-8"
          placeholder="Enter sticker keywords, characters, etc..."
          onChange={(e) => setFilter(e.target.value)}
        />

        {isErrored && <ErrorBlock />}
        {isLoading && !isErrored && (
          <div className="bg-white mx-auto w-5/6 lg:w-1/3 p-4 min-h-[400px] rounded-md flex flex-col items-center justify-center mt-12">
            <Loading type="spin" color="#4178C5" width={200} height={200} />
          </div>
        )}
        {!isLoading && (
          <div
            className={`bg-white rounded-md p-4`}
            ref={blockRef}
            style={{
              maxHeight: sectionHeight,
              height: sectionHeight,
              minHeight: sectionHeight,
            }}
          >
            {filteredAvatars && filteredAvatars.length > 0 ? (
              <VirtuosoGrid
                style={{ height: sectionHeight - 80 }}
                overscan={200}
                totalCount={filteredAvatars.length}
                itemClassName={itemClassName}
                listClassName="flex flex-row flex-wrap justify-center"
                components={{
                  ScrollSeekPlaceholder: ({ height, width, index }) => (
                    <div className="bg-gray-300 animate-pulse w-full" />
                  ),
                }}
                itemContent={(index) => {
                  const avatar: any = filteredAvatars[index];
                  return (
                    <div style={{ height: height > 900 ? 350 : 240 }}>
                      <button
                        className="bg-gray-300 rounded-md"
                        onClick={() => {
                          setSelectedAvatarId(avatar.id);
                        }}
                      >
                        {avatar && (
                          <img
                            src={avatar.image}
                            id={avatar.id}
                            alt=""
                            width="100%"
                          />
                        )}
                      </button>
                      <div className="flex justify-between align-center items-center">
                        <span className="text-xs">{avatar.name}</span>
                        <div className="flex flex-row items-center justify-center space-x-1">
                          <span className="text-xs">
                            {localLikes[avatar.id] || avatar.likeCount || 0}
                          </span>
                          <button
                            disabled={favorites[avatar.id]}
                            onClick={async () => {
                              let newFavorites;
                              newFavorites = {
                                ...favorites,
                                [avatar.id]: avatar.id,
                              };

                              setFavorites(newFavorites);

                              try {
                                const likeCountResponse = await giveLike(
                                  avatar.id
                                );

                                setLocalLikes((previous) => ({
                                  ...previous,
                                  [avatar.id]: likeCountResponse.likeCount,
                                }));
                              } catch (error) {
                                // TODO: Add error logging
                              }
                            }}
                          >
                            {!!favorites[avatar.id] ? (
                              <HeartActive />
                            ) : (
                              <HeartInactive />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                }}
              />
            ) : (
              <h2 className="color-gray-200 text-xl md:text-3xl font-extrabold mx-auto w-full flex items-center justify-center">
                No Trailmoji found. Please adjust your filter.
              </h2>
            )}
          </div>
        )}
      </div>
      <Dialog
        open={!!selectedAvatarId}
        onClose={onClose}
        className="z-40 absolute top-0 right-0 left-0 bottom-0"
      >
        <Dialog.Overlay
          className="bg-black fixed top-0 bottom-0 left-0 right-0 opacity-80 z-10"
          onClick={onClose}
        />
        <Dialog.Title hidden={true}></Dialog.Title>
        {selectedAvatar && selectedAvatar?.image && selectedAvatar?.id && (
          <div className="relative z-20 mx-auto w-[600px] mt-40 bg-gray-300 rounded-md p-24">
            <div className=" mx-auto flex items-center justify-center flex-col">
              <img src={selectedAvatar?.image} id={selectedAvatar?.id} alt="" />
            </div>
          </div>
        )}
      </Dialog>
    </Layout>
  );
};

export default Gallery;
