const face = `
<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<svg id="b2e50cc9-db4f-485a-9190-a1ebc71976c8" data-name="SENT - Original Astro&apos;s face" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1260"><title>face</title>
<g>
    <g id="ec679c84-07fd-43fb-b3c0-1a352635f98d" data-name="Astro-original skin tone">
        <path d="M671.8,641.06c0,26.2-53.77,58.74-132.7,58.74s-137.89-26.25-137.89-52.45,59,14.32,137.89,14.32S671.8,614.85,671.8,641.06Z" style="fill:#222;opacity:0.15"/>
        <path id="Colorable" d="M731.94,493.24c2.15,81.53-54.08,152.15-133.74,178a215.73,215.73,0,0,1-61.1,10.43c-85.55,2.25-159.85-45.2-190.1-113.81-.87-2-1.68-4-2.51-6a169.51,169.51,0,0,1-11.86-58.05C330,402.61,421.19,315.44,531.46,312.53a207,207,0,0,1,58.73,6.8C671.33,340.94,729.77,410.88,731.94,493.24Z" style="fill:#d0af88"/>
        <path id="Colorable-1" d="M733.43,548.71C728.88,562.39,721.05,572,714.91,572a171.9,171.9,0,0,0,9.62-24.5,210.27,210.27,0,0,0,5.64-24.86C739.46,525.1,737.93,535.16,733.43,548.71Z" style="fill:#d0af88"/>
        <path d="M733.43,548.71c-4.6,13.67-12.38,23.31-18.52,23.31a171.9,171.9,0,0,0,9.62-24.5,210.27,210.27,0,0,0,5.64-24.86C739.46,525.1,738,535.05,733.43,548.71Z" style="fill:#222;opacity:0.1"/>
        <path id="Colorable-2" d="M330.4,548.71C335,562.39,342.78,572,348.92,572a194.55,194.55,0,0,1-9.31-24.5,186.15,186.15,0,0,1-5.43-24.86C324.88,525.1,325.9,535.16,330.4,548.71Z" style="fill:#d0af88"/>
        <path d="M330.4,548.71C335,562.38,342.78,572,348.92,572a194.55,194.55,0,0,1-9.31-24.5,186.15,186.15,0,0,1-5.43-24.86C324.88,525.1,325.81,535.05,330.4,548.71Z" style="fill:#222;opacity:0.1"/>
    </g>
</g>
</svg>
`

export default face