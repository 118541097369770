const forceDownload = (blob, filename) => {
  var a = document.createElement("a");
  a.download = filename;
  a.href = blob;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const downloadResource = async (
  url,
  filename,
  setIsDownloading?: any
) => {
  if (!filename) filename = url.split("\\").pop().split("/").pop();
  fetch(url, {
    headers: new Headers({
      Origin: window.location.origin,
      mode: "no-cors",
    }),
  })
    .then((response) => response.blob())
    .then((blob) => {
      let blobUrl = window.URL.createObjectURL(blob);
      forceDownload(blobUrl, filename);
      setIsDownloading && setIsDownloading(false);
    })
    .catch((e) => {
      setIsDownloading && setIsDownloading(false);
    });
};
