import ReactDOM from "react-dom";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Stylewind from "./Stylewind";
import classnames from "classnames";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { useMatch } from "react-router-dom";
import {
  breakpointTypes,
  useTailwindBreakpoint,
} from "../hooks/useTailwindBreakpoint";

const NavList = Stylewind``;
NavList.defaultProps = {
  as: "ul",
  className: "flex flex-row pl-16 md:pl-0 md:items-center md:space-x-16",
};
const NavListItem = Stylewind``;
NavListItem.defaultProps = {
  as: "li",
  className: "list-none text-white text-lg font-bold mt-6 ml-2",
};

interface NavItemProps {
  name: string;
  to: string;
  active?: boolean;
}

const navItems: NavItemProps[] = [
  {
    name: "Home",
    to: "/",
  },
  {
    name: "Stickers",
    to: "/stickers",
  },
  {
    name: "myTrailmoji",
    to: "/mytrailmoji",
  },
  {
    name: "Gallery",
    to: "/gallery",
  },
];

const NavItem = ({ item, addClassName }) => {
  const match = useMatch(item.to);
  return (
    <NavListItem
      key={item.name}
      addClassName={classnames(addClassName, {
        "border-b-yellow-400": match,
        "border-b-4": match,
      })}
    >
      <Link to={item.to}>{item.name}</Link>
    </NavListItem>
  );
};
// A navigation component that loops through the navItems array and renders links
export const Navigation = ({
  addClassName,
  isFooter,
}: {
  addClassName?: string;
  isFooter?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const breakpoint = useTailwindBreakpoint();

  // used to update scrolling on the body
  useEffect(() => {
    const body = document.querySelector("body");
    if (!body) return;

    if (isOpen && breakpoint === breakpointTypes.sm) {
      body.classList.add("overflow-hidden");
      body.classList.add("w-full");
      body.classList.add("h-full");
    } else {
      body.classList.remove("overflow-hidden");
      body.classList.remove("w-full");
      body.classList.remove("h-full");
    }
  }, [isOpen, breakpoint]);

  const nav = (
    <nav>
      <NavList>
        {navItems.map((item) => (
          <NavItem item={item} addClassName={addClassName} key={item.name} />
        ))}
      </NavList>
    </nav>
  );

  if (isFooter) {
    return nav;
  }

  return breakpoint === breakpointTypes.sm ? (
    <>
      <button
        className="absolute right-8 top-2"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <MenuIcon className="w-6 h-6 text-white" />
      </button>
      {ReactDOM.createPortal(
        <div
          className={classnames(
            "fixed bg-blue-700 h-full top-0 right-0 duration-200 transform-translate-x-full transition-[width] shadow-l-lg",
            isOpen ? "w-4/5" : "w-0"
          )}
          style={{ zIndex: 80 }}
        >
          <button
            className="absolute left-4 top-4"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <XIcon className="w-6 h-6 text-white" />
          </button>

          {nav}
        </div>,
        document.body
      )}
    </>
  ) : (
    nav
  );
};

export default Navigation;
