import axios from "axios";

const contentApi = axios.create({
  baseURL: `${process.env.REACT_APP_CONTENT_URL}`,
});
const mytrailmojiApi = axios.create({
  baseURL: `${process.env.REACT_APP_MYTRAILMOJI_API_URL}`,
});

export const fetchStickers = async () => {
  try {
    const result = await contentApi.get("/stickers.json");
    return result.data;
  } catch (error) {
    console.log("ERRORR", error);
  }
};

export const fetchMainCategories = async () => {
  try {
    const result = await contentApi.get("/trailmoji-categories");
    return result.data;
  } catch (error) {}
};

export const fetchTrailmojiMe = async () => {
  try {
    const result = await contentApi.get("/dreamojime-v2");
    return result.data;
  } catch (error) {}
};

export const saveMyTrailmoji = async (traits) => {
  try {
    const result = await mytrailmojiApi.post("/generatemytrailmoji", traits);
    return result.data;
  } catch (error) {
    console.log(`Failed to save avatar: ${error}`);
    return null;
  }
};

export const giveLike = async (trailmojiId) => {
  try {
    const result = await mytrailmojiApi.get(`/givelike/${trailmojiId}`);
    return result.data;
  } catch (ex) {
    return null;
  }
};
