import React, { useState, useEffect } from "react";
import axios from "axios";
import { Buffer } from "buffer";
import XMLParser from "react-xml-parser";
import { skinCategory, baseLayers } from "./constants";

const getSkinTone = (selectedTone) => {
  return skinCategory.items[selectedTone ?? 0].skinTone;
};

const Avatar = ({
  categories,
  itemSelections,
  setAvatarTraits,
  selectedColors: selectedItemColors,
}: {
  categories: any;
  itemSelections: any;
  setAvatarTraits?: any;
  selectedColors?: any;
}) => {
  const xmlParser = new XMLParser();
  const [downloadedSVGs, setDownloadedSVGs] = useState({});
  const skinTone = getSkinTone(itemSelections && itemSelections[0]);

  useEffect(() => {
    const fetchSVGs = async () => {
      itemSelections.map(async (itemIndex, categoryIndex) => {
        // Remove from downloadedSVGs
        if (categories[categoryIndex]?.items[itemIndex]?.id === "-1") {
          return;
        }

        categories[categoryIndex]?.items[itemIndex]?.svgLayers?.map(
          async (layer) => {
            const svgLayerUrl = layer.svgAsset;
            const svgImage = await axios(layer.svgAsset);
            setDownloadedSVGs((prev) => {
              return {
                ...prev,
                [svgLayerUrl]: svgImage.data,
              };
            });
          }
        );
      });
    };
    fetchSVGs();
  }, [itemSelections, categories]);

  // FIXME: This could be moved to outside to parent if desired
  const generateAvatarJsonForImageProcessing = () => {
    var avatarTraits: any[] = [];

    baseLayers.forEach((layer) => {
      // Check if any base layer is disabled
      for (var index = 0; index < categories.length; index++) {
        const category = categories[index];
        const selectedItemIndex = itemSelections[index];
        const selectedItem = category?.items[selectedItemIndex];
        if (
          selectedItem?.hideLimbs != null &&
          selectedItem?.hideLimbs.includes(layer.id)
        ) {
          return;
        }
      }
      if (layer.id === "face") {
        avatarTraits.push({
          id: layer.id,
          order: layer.order * 100,
          color: skinTone,
        });
      } else {
        avatarTraits.push({ id: layer.id, order: layer.order * 100 });
      }

      setAvatarTraits && setAvatarTraits(avatarTraits);
    });

    itemSelections.forEach((itemIndex, categoryIndex) => {
      if (!categories[categoryIndex] || !categories[categoryIndex].items) {
        return;
      }
      const item = categories[categoryIndex]?.items[itemIndex];
      if (!item || item.id === "-1") {
        return;
      }
      item.svgLayers &&
        item.svgLayers.forEach((layer, index) => {
          var itemColor = item.colorable ? item.colorable : undefined;
          if (selectedItemColors && selectedItemColors[item.id] !== undefined) {
            itemColor = selectedItemColors[item.id];
          }
          avatarTraits.push({
            id: layer.id,
            order: layer.layerOrder * 100 + parseInt(layer.sortOrder),
            color: itemColor,
          });
        });
    });
  };

  const renderCategoryItems = (itemIndex, categoryIndex) => {
    const item = categories[categoryIndex]?.items[itemIndex];

    // Do not draw any item for "no selection"
    if (!item || item.id === "-1") {
      return;
    }

    return (
      item.svgLayers &&
      item.svgLayers.map((layer, index) => {
        if (downloadedSVGs[layer.svgAsset] === undefined) {
          return null;
        }

        var itemColor = item.colorable;
        if (selectedItemColors[item.id] !== undefined) {
          itemColor = selectedItemColors[item.id];
        }

        // Replace colors
        const svgJson = replaceColor(
          xmlParser.parseFromString(downloadedSVGs[layer.svgAsset]),
          itemColor
        );
        var parsedSVG = xmlParser.toString(svgJson);
        // Bug in xml parser > string
        parsedSVG = parsedSVG.replace("</title></title>", "</title>");
        const encodedSVG = new Buffer(parsedSVG).toString("base64");
        return (
          <img
            key={layer.id}
            src={`data:image/svg+xml;base64,${encodedSVG}`}
            style={{
              zIndex: layer.layerOrder * 100 + parseInt(layer.sortOrder),
            }}
            className="absolute w-full h-full"
            alt={layer.id}
          />
        );
      })
    );
  };

  const replaceColor = (json, color) => {
    const jsonChildren = json["children"].map((child) => {
      if (child["children"] !== undefined) {
        child = replaceColor(child, color);
      }

      if (
        child["attributes"]["id"] !== undefined &&
        child["attributes"]["id"].includes("Colorable")
      ) {
        return {
          ...child,
          attributes: { ...child.attributes, style: `fill: ${color}` },
        };
      }

      return child;
    });

    return { ...json, children: jsonChildren };
  };

  const renderBaseLayer = (layer) => {
    // Check if any base layer is disabled
    for (var index = 0; index < categories.length; index++) {
      const category = categories[index];
      const selectedItemIndex = itemSelections[index];
      const selectedItem = category.items[selectedItemIndex];
      if (
        selectedItem?.hideLimbs != null &&
        selectedItem?.hideLimbs.includes(layer.id)
      ) {
        return;
      }
    }

    // Use skinton for face
    if (layer.id === "face") {
      // Replace colors
      const svgJson = replaceColor(
        xmlParser.parseFromString(layer.svg),
        skinTone
      );
      var parsedSVG = xmlParser.toString(svgJson);
      // Bug in xml parser > string
      parsedSVG = parsedSVG.replace("</title></title>", "</title>");
      const encodedSVG = new Buffer(parsedSVG).toString("base64");

      return (
        <img
          key={layer.id}
          src={`data:image/svg+xml;base64,${encodedSVG}`}
          style={{ zIndex: layer.order * 100 }}
          className="absolute w-full h-full"
          alt={layer.id}
        />
      );
    }

    return (
      <img
        key={layer.id}
        src={layer.svg}
        style={{ zIndex: layer.order * 100 }}
        className="absolute w-full h-full min-h-full "
        alt={layer.id}
      />
    );
  };

  // FIXME: For testing. Used to generated JSON for server to convert to PNG
  generateAvatarJsonForImageProcessing();

  return (
    <div className="avatarContainer absolute right-0 left-0 top-0 bottom-0 z-10">
      {itemSelections.map((itemIndex, categoryIndex) =>
        renderCategoryItems(itemIndex, categoryIndex)
      )}
      {baseLayers.map((layer) => renderBaseLayer(layer))}
    </div>
  );
};

export default Avatar;
