import Layout from "../../components/Layout";
import { VirtuosoGrid } from "react-virtuoso";
import Loading from "react-loading";
import { Tab, Dialog } from "@headlessui/react";
import { Smile, Quote } from "./Icons";
import { useLayoutEffect, useRef, useState, useEffect } from "react";

import resolveConfig from "tailwindcss/resolveConfig";
import localConfig, { content } from "../../tailwind.config";
import { useWindowSize } from "../../hooks/useWindowSize";
import { fetchStickers } from "../../utils/stickersApi";
import { downloadResource } from "../../utils/download";
import ErrorBlock from "../../components/ErrorBlock";
import { useContentSectionHeight } from "../../hooks/useContentSectionHeight";

const config = resolveConfig(localConfig as any);

const baseTabClass =
  "mr-5 rounded-t-lg w-24 flex flex-row items-center justify-center p-4 box-border fill-blue-500 w-[64px] h-[64px] md:w-auto md:h-auto";

const tabClass = `bg-blue-700 ${baseTabClass}`;
const selectedTabClass = `bg-white ${baseTabClass}`;

const LocalTab = ({ ...props }) => {
  return (
    <Tab
      {...props}
      className={({ selected }) => (selected ? selectedTabClass : tabClass)}
    />
  );
};

const useStickers = () => {
  const [state, setState] = useState({
    stickers: undefined,
    isLoading: true,
    isErrored: false,
  });
  useEffect(() => {
    const getStickers = async () => {
      try {
        const stickerData = await fetchStickers();
        setState({ stickers: stickerData, isLoading: false, isErrored: false });
      } catch (ex) {
        setState({ stickers: undefined, isLoading: false, isErrored: true });
      }
    };

    getStickers();
  }, []);

  return state;
};

const StickerGrid = ({
  collection,
  contentHeight,
}: {
  collection: any;
  contentHeight?: number;
}) => {
  const [selectedSticker, setSelectedSticker] = useState<any>();
  const onClose = () => {
    setSelectedSticker(undefined);
  };

  const { height: windowHeight } = useWindowSize();

  return (
    <>
      <div className="z-0">
        <VirtuosoGrid
          style={{ height: contentHeight }}
          overscan={200}
          totalCount={collection.length}
          itemClassName="h-28 w-28 md:w-52 md:h-52 p-4"
          listClassName="flex flex-row flex-wrap justify-center"
          components={{
            ScrollSeekPlaceholder: ({ height, width, index }) => (
              <div className="bg-gray-300 animate-pulse w-full" />
            ),
          }}
          itemContent={(index) => {
            const sticker = collection[index];
            return (
              <button
                onClick={() => {
                  setSelectedSticker(collection[index]);
                }}
              >
                <img
                  src={sticker.imageUrl}
                  id={sticker.id}
                  alt=""
                  width="100%"
                />
              </button>
            );
          }}
        />
      </div>
      <Dialog
        open={!!selectedSticker}
        onClose={onClose}
        className="z-40 absolute top-0 right-0 left-0 bottom-0"
      >
        <Dialog.Overlay
          className="bg-white absolute top-0 bottom-0 left-0 right-0 opacity-80 z-10"
          onClick={onClose}
        />
        <Dialog.Title hidden={true}></Dialog.Title>
        {selectedSticker && (
          <div className="relative z-20 mx-auto w-[400px] mt-40">
            <div className=" mx-auto flex items-center justify-center flex-col">
              <img
                src={selectedSticker.imageUrl}
                id={selectedSticker.id}
                alt=""
              />
              <button
                className="bg-yellow-500 text-white rounded-md py-4 px-8 my-4"
                onClick={() => {
                  downloadResource(
                    selectedSticker.imageUrl.replace('https://cdn.dreamoji.app/', 'https://dreamoji.s3.us-west-1.amazonaws.com/'),
                    selectedSticker.id
                  );
                }}
              >
                Download Sticker
              </button>
            </div>
          </div>
        )}
      </Dialog>
    </>
  );
};

const Stickers = () => {
  const inputRef: any = useRef(null);
  const { width } = useWindowSize();
  const [filter, setFilter] = useState("");
  const [activeTab, setActiveTab] = useState<number>(0);
  const { stickers, isLoading, isErrored }: any = useStickers();

  const contentBlock = useRef(null);
  const { height } = useContentSectionHeight(contentBlock, isLoading);
  const activeCategory =
    stickers?.categories.length > 0 ? stickers.categories[activeTab] : null;

  const filteredStickers =
    filter.length > 0
      ? activeCategory?.stickers.filter((sticker: any) => {
          const includedCharacter = sticker.characters.some((c) =>
            c.toLowerCase().includes(filter.toLowerCase())
          );
          return (
            (sticker.keywords &&
              sticker.keywords.toLowerCase().includes(filter.toLowerCase())) ||
            includedCharacter
          );
        })
      : activeCategory?.stickers;

  useLayoutEffect(() => {
    if (!isErrored) {
      const tabs = document.querySelector(".stickers-tabs");
      const childNodes = tabs?.childNodes || [];
      let width = 0;
      // we could coerce this into something mappable but we'll use for loop for now
      for (let i = 0; i < childNodes.length; i++) {
        const tab = childNodes[0];
        width += (tab as any).offsetWidth + 20;
      }

      const containerWidth = (tabs as any)?.offsetWidth || 0;
      inputRef.current.style.width = `${containerWidth - width}px`;
    }
  }, [width]);

  const contentHeight = height - 150;
  const gray = (config as any).theme.colors.gray["300"];
  return (
    <Layout
      backgroundType={activeTab === 0 ? "stickers" : "sayings"}
      rootBackgroundColor={gray}
    >
      <h2 className="mx-auto text-white text-2xl my-9 flex justify-center items-center">
        {activeTab === 0 ? "Characters" : "Sayings"}
      </h2>
      <div className={`relative w-full md:w-2/3 mx-auto`} ref={contentBlock}>
        {isErrored && <ErrorBlock />}
        {!isErrored && (
          <>
            <input
              type="text"
              ref={inputRef}
              onChange={(e) => setFilter(e.target.value)}
              placeholder="Enter sticker keywords, characters, etc..."
              className="absolute top-0 left-4 w-1/2 p-4 py-4 rounded-lg block md:hidden"
            ></input>
            <Tab.Group
              onChange={(tab) => {
                setActiveTab(tab);
              }}
            >
              <Tab.List className="flex stickers-tabs ml-4 md:ml-0 justify-end md:justify-start">
                <LocalTab>
                  <Smile />
                </LocalTab>
                <LocalTab>
                  <Quote />
                </LocalTab>
              </Tab.List>
              <Tab.Panels
                className={`bg-white rounded-md rounded-tl-none p-4 md:p-8 mx-4 md:mx-0 overflow-hidden`}
                style={{ maxHeight: height - 150 }}
              >
                <Tab.Panel>
                  {activeTab === 0 &&
                    (filteredStickers?.length > 0 ? (
                      <StickerGrid
                        collection={filteredStickers}
                        contentHeight={contentHeight - 50}
                      />
                    ) : isLoading ? (
                      <Loading
                        type="spin"
                        color="#4178C5"
                        width={100}
                        height={100}
                        className="mx-auto my-24"
                      />
                    ) : (
                      <div>No results found. Please adjust your filters</div>
                    ))}
                </Tab.Panel>
                {!isLoading && (
                  <Tab.Panel>
                    {activeTab === 1 &&
                      (filteredStickers.length > 0 ? (
                        <StickerGrid
                          collection={filteredStickers}
                          contentHeight={contentHeight}
                        />
                      ) : (
                        <div>No results found. Please adjust your filters</div>
                      ))}
                  </Tab.Panel>
                )}
              </Tab.Panels>
            </Tab.Group>
            <input
              type="text"
              ref={inputRef}
              onChange={(e) => setFilter(e.target.value)}
              placeholder="Enter sticker keywords, characters, etc..."
              className="absolute top-0 right-0 w-full p-4 py-4 rounded-lg md:block hidden"
            ></input>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Stickers;
