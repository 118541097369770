export const Smile = () => (
  <svg
    width="51"
    height="50"
    viewBox="0 0 51 50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.35974 16.9184C9.27805 16.9184 11.6438 13.3484 11.6438 8.94465C11.6438 4.5409 9.27805 0.970947 6.35974 0.970947C3.44144 0.970947 1.07568 4.5409 1.07568 8.94465C1.07568 13.3484 3.44144 16.9184 6.35974 16.9184Z" />
    <path d="M44.4852 16.9184C47.4035 16.9184 49.7693 13.3484 49.7693 8.94465C49.7693 4.5409 47.4035 0.970947 44.4852 0.970947C41.5669 0.970947 39.2012 4.5409 39.2012 8.94465C39.2012 13.3484 41.5669 16.9184 44.4852 16.9184Z" />
    <path d="M0.0991211 29.4033H50.9065C50.9065 29.4033 50.2632 49.9071 25.5085 49.6928C0.753887 49.4786 0.0991211 29.4033 0.0991211 29.4033Z" />
  </svg>
);

export const Quote = () => (
  <svg
    width="53"
    height="43"
    viewBox="0 0 53 43"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21.0532 0.941895V11.1086C17.4769 11.8239 15.0967 12.8793 13.9244 14.2982C12.7402 15.7171 12.1599 18.4024 12.1599 22.3425H21.2071V42.5352H0.81543V20.6773C0.81543 14.908 2.57985 10.2057 6.10873 6.55881C9.63761 2.91192 14.623 1.03571 21.0532 0.941895ZM52.8012 0.941895V11.1086C49.225 11.8239 46.8448 12.8793 45.6724 14.2982C44.4882 15.7171 43.908 18.4024 43.908 22.3425H52.9552V42.5352H32.5753V20.6773C32.5753 14.908 34.3398 10.2057 37.8568 6.55881C41.3738 2.91192 46.3474 1.03571 52.8012 0.941895Z" />
  </svg>
);
