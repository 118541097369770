import { useEffect, useState } from "react";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../tailwind.config";
import { useWindowSize } from "./useWindowSize";

const fullConfig = resolveConfig(tailwindConfig as any);

// via: https://stackoverflow.com/questions/59982018/how-do-i-get-tailwinds-active-breakpoint-in-javascript
export const getBreakpointValue = (value: any) => {
  // @ts-ignore
  return +fullConfig.theme.screens[value].slice(
    0,
    // @ts-ignore
    fullConfig.theme.screens[value].indexOf("px")
  );
};

export const breakpointTypes = {
  sm: undefined,
  md: "md",
  lg: "lg",
  xl: "xl",
  "2xl": "2xl",
  "3xl": "3xl",
  "4xl": "4xl",
};

export const getCurrentBreakpoint = () => {
  let currentBreakpoint;
  let biggestBreakpointValue = 0;
  // @ts-ignore
  for (const breakpoint of Object.keys(fullConfig.theme.screens)) {
    const breakpointValue = getBreakpointValue(breakpoint);
    if (
      breakpointValue > biggestBreakpointValue &&
      window.innerWidth >= breakpointValue
    ) {
      biggestBreakpointValue = breakpointValue;
      currentBreakpoint = breakpoint;
    }
  }
  return currentBreakpoint;
};

export const useTailwindBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(getCurrentBreakpoint());
  const { width } = useWindowSize();
  useEffect(() => {
    setBreakpoint(getCurrentBreakpoint());
  }, [width]);

  return breakpoint;
};
