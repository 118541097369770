import logo from "./logo.png";
import Stylewind from "../Stylewind";
import { Navigation } from "../Navigation";

const HeadingWrapper = Stylewind``;
HeadingWrapper.defaultProps = {
  className: "flex flex-col mx-24",
  id: "heading-wrapper",
};

const Logo = () => {
  return <img src={logo} width={260} alt="Trailmoji Logo" />;
};

const Heading = () => {
  return (
    <HeadingWrapper>
      <Logo />
      <Navigation />
    </HeadingWrapper>
  );
};

export default Heading;
