import { useState, useEffect } from "react";
import queryString from "query-string";

const getQueryString = (key) => {
  const queryParameters = queryString.parse(window.location.search);
  return queryParameters[key];
};

const setQueryString = (key, value) => {
  const originalQueryString = queryString.parse(window.location.search);
  const nextQueryString = queryString.stringify({
    ...originalQueryString,
    [key]: value,
  });
  const location = window.location;
  const { protocol, host, pathname } = location;
  const nextUrl = `${protocol}//${host}${pathname}?${nextQueryString}`;

  window.history.pushState({ path: nextUrl }, "", nextUrl);
};

// This is like useState but writes to queryString as well
export function useQueryState(key, defaultValue) {
  const [value, setValue] = useState(getQueryString(key) || defaultValue);

  useEffect(() => {
    setQueryString(key, value);
  }, [value, key]);

  return [value, setValue];
}
