import React, { FC, StyleHTMLAttributes, useEffect } from "react";
import classnames from "classnames";
import stickers from "./trailmoji_stickers_background.png";
import flair from "./flair_stickers_background.png";
import trailmoji from "./trailmoji_background1.png";
import standalone from "./trailmoji_home_standalone.png";
import gallery from "./gallery.png";

import mobileBg from "./heading_bg_mobile.png";
import main from "./main.png";
import Heading from "../Heading";
import characterLeft from "./character_left.png";
import characterRight from "./character_right.png";
import sayingLeft from "./saying_left.png";
import sayingRight from "./saying_right.png";
import trailmojiLeft from "./trailmoji_left.png";
import trailmojiRight from "./trailmoji_right.png";
import terrain from "./terrain.png";
import mainLeft from "./main_left.png";
import mainRight from "./main_right.png";
import Stylewind from "../Stylewind";
import config from "../../tailwind.config";
import {
  breakpointTypes,
  useTailwindBreakpoint,
} from "../../hooks/useTailwindBreakpoint";
import classNames from "classnames";

type BackgroundType = "stickers" | "sayings" | "trailmoji" | "main" | "gallery";

interface Props {
  backgroundType?: BackgroundType;
  children: any;
  hero?: any;
  position?: "relative" | "absolute";
  rootBackgroundColor?: string;
  addClassName?: string;
}

interface BackgroundBlockProps {
  backgroundType: BackgroundType;
  children: any;
}

type SplitType = "main" | "character" | "trailmoji" | "saying";
interface SplitBackgroundBlockProps {
  children: any;
  type?: SplitType;

  [key: string]: any;
}

const SplitImage = Stylewind``;

SplitImage.defaultProps = {
  as: "img",
  className: "md:absolute bottom-0 bg-contain z-0 max-h-full",
};

interface SplitImageProps {
  leftSrc: string;
  rightSrc: string;
  hideMobileBackground?: boolean;
  lockWidth?: boolean;
}

const SplitImageContainer: FC<SplitImageProps> = ({
  leftSrc,
  rightSrc,
  hideMobileBackground,
  lockWidth,
}) => {
  const breakpoint = useTailwindBreakpoint();
  return breakpoint !== breakpointTypes.sm && breakpoint !== undefined ? (
    <div className="md:absolute md:bottom-0 w-full md:h-full md:z-0">
      <SplitImage
        addClassName="left-0 hidden md:block max-w-1/3"
        style={{ objectFit: "contain" }}
        src={leftSrc}
      />
      <SplitImage
        addClassName={classnames("right-0 max-w-1/3", {
          "w-1/3 ": lockWidth,
        })}
        src={rightSrc}
        style={{ objectFit: "contain" }}
      />
    </div>
  ) : !hideMobileBackground ? (
    <div className="w-full relative">
      <img
        src={mobileBg}
        alt=""
        className="z-0 absolute bottom-0 left-0 right-0 w-full"
      />
      <img
        src={standalone}
        alt=""
        className="relative z-10 w-2/3 mx-auto -mt-12 pb-12"
      />
    </div>
  ) : null;
};

const Background = Stylewind`
  @media (min-width: 780px) {
    min-height: ${config.headerHeight}px;
    height: ${config.headerHeight}px;
  }
`;

Background.defaultProps = {
  as: "div",
  className:
    "relative pt-3 md:pt-16 flex flex-col md:flex-col bg-no-repeat bg-x-no-repeat bg-blue-400",
};

const getImagesByType = (type?: SplitType) => {
  switch (type) {
    case "main":
      return { left: mainLeft, right: mainRight };
    case "character":
      return { left: characterLeft, right: characterRight };
    case "saying":
      return { left: sayingLeft, right: sayingRight };
    case "trailmoji":
      return { left: trailmojiLeft, right: trailmojiRight };
    default:
      return { left: mainLeft, right: mainRight };
  }
};
const SplitBackgroundBlock: FC<SplitBackgroundBlockProps> = ({
  children,
  type,
  ...rest
}) => {
  const images = getImagesByType(type);
  const oneOffStyle =
    type === "trailmoji"
      ? {
          background: `url(${terrain})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
        }
      : {};
  return (
    <Background {...rest} style={oneOffStyle}>
      <SplitImageContainer
        leftSrc={images.left}
        rightSrc={images.right}
        hideMobileBackground={type !== "main"}
        lockWidth={type === "main"}
      />
      <div className="z-10 relative">{children}</div>
    </Background>
  );
};

type GetBackgroundImage = (backgroundType: BackgroundType) => string;
const getBackgroundImage: GetBackgroundImage = (
  backgroundType: BackgroundType
) => {
  if (backgroundType === "stickers") {
    return stickers;
  } else if (backgroundType === "sayings") {
    return flair;
  } else if (backgroundType === "gallery") {
    return gallery;
  } else if (backgroundType === "main") {
    return main;
  } else {
    return trailmoji;
  }
};

const StandardLayout = ({ children, addClassName, type }) => (
  <div>
    <SplitBackgroundBlock addClassName={addClassName} type={type}>
      <Heading />
      {children}
    </SplitBackgroundBlock>
  </div>
);
const Layout: FC<Props> = ({
  children,
  hero,
  backgroundType = "stickers",
  position = "relative",
  addClassName,
  rootBackgroundColor,
}) => {
  useEffect(() => {
    if (rootBackgroundColor) {
      document.documentElement.style.setProperty(
        "--primary",
        rootBackgroundColor
      );
    }
  }, []);

  switch (backgroundType) {
    case "main":
      return (
        <div>
          <SplitBackgroundBlock addClassName={addClassName} type="main">
            <Heading />
            {hero}
          </SplitBackgroundBlock>
          {children}
        </div>
      );
    case "stickers":
      return (
        <StandardLayout
          addClassName={addClassName}
          type="character"
          children={children}
        />
      );
    case "sayings":
      return (
        <StandardLayout
          addClassName={addClassName}
          type="saying"
          children={children}
        />
      );
    case "trailmoji":
      return (
        <StandardLayout
          addClassName={addClassName}
          type="trailmoji"
          children={children}
        />
      );
    default:
      return (
        <div>
          <Background
            addClassName={addClassName}
            backgroundType={backgroundType}
            style={{
              background: `url(${getBackgroundImage(backgroundType)})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Heading />
            {children}
          </Background>
        </div>
      );
  }
};

export default Layout;
