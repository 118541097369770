import React from "react";

const ErrorBlock = ({ children }: { children?: any }) => {
  return (
    <div className="bg-white mx-auto w-5/6 lg:w-1/3 p-4 min-h-[400px] rounded-md flex flex-col items-center justify-center mt-12">
      {children || (
        <h2 className="color-black text-3xl font-extrabold">
          We're sorry but an error occurred.
        </h2>
      )}
    </div>
  );
};

export default ErrorBlock;
